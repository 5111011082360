import React from "react";
//import Nova from "./Nova";
import Brain from "./Brain";


const Hero = () => {
  return (
    <div className="containerHero">
      <div>
        <Brain />
      </div>
      <div
        className="hero flex h-screen flex-col justify-center lg:mr-44"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div className="">
          <div className="hero-title font-['SpaceGrotesk'] text-5xl font-bold text-white md:text-7xl ">
          {/* <div className="hero-title font-['SpaceGrotesk'] text-5xl font-bold text-main-color md:text-7xl "> */}
            <h1>AIPeakFlow</h1>
          </div>
          <p className="mt-[24px] w-2/3 max-w-[380px] font-['Inter'] text-base md:text-lg font-semibold md:font-normal text-white">
           {/* <p className="mt-[24px] w-2/3 max-w-[380px] font-['Inter'] text-base md:text-lg font-semibold md:font-normal text-main-color"> */}
            Empowering Businesses to Scale New Heights Through Intelligent Automation.
          </p>
          <div className="flex gap-x-3 pt-5  font-['Inter']">
            <a href="#contact">
              <input
                type="button"
                className="btn blue-btn"
                value="Let's talk"
              />
            </a>
            <a href="#services">
              <input
                type="button"
                className="btn border-btn normal-btn"
                value="Our services"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
