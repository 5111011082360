import React, { useEffect, useRef } from 'react';
const Marquee = () => {
  const marqueeRef = useRef(null);
  let xValue = 0;

  const logos = [
    'openai logo.svg',
    'google ai logo.svg',
    'stack ai logo.svg',
    'hubspot logo.svg',
    'slack logo.svg',
    'voiceflow logo.svg',
    'zapier logo.svg',
    'dialogflow logo.svg',
    'make logo.svg'
  ];

  useEffect(() => {
    function animate() {
      if (xValue <= -100) {  // Reset at -100% to match the 200vw width
        xValue = 0;
      }
      marqueeRef.current.style.transform = `translateX(${xValue}%)`;
      xValue -= 0.04;  // Reduced for slower motion
      requestAnimationFrame(animate);
    }
    animate();
  }, []);

  return (
    <div className="marquee_container">
      <div className="marquee_wrap" ref={marqueeRef}>
        {logos.map((logo, index) => (
          <img
            key={index}
            src={`/images/companiesLogos/${logo}`}
            className="logo-2"
            alt={logo}
          />
        ))}
        {/* Duplicate the logos */}
        {logos.map((logo, index) => (
          <img
            key={index + logos.length}  // Ensure unique keys
            src={`/images/companiesLogos/${logo}`}
            className="logo-2"
            alt={logo}
          />
        ))}
      </div>
    </div>
  );
};

export default Marquee;
