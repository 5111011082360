import React, { useState } from 'react';
import styles from './FAQSection.module.css';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

 const faqs = [
  {
    question: "What's the advantage of using your service instead of hiring a dedicated pro?",
    answer: "Hiring such a developer is a significant investment. Also you may not always have enough work to keep a full-time senior-level AI & automations developer busy at all times, which means you're stuck paying for time you can't utilize. With our Unlimited AI & Automation Development Plan, you get the expertise of a whole team for a fixed monthly fee. This means you can submit an unlimited number of tasks without worrying about hidden costs. We offer the flexibility and scalability that a single full-time specialist can't provide, all while maintaining the highest quality standards."
  },
  {
    question: "What do you offer in terms of services?",
    answer: "We're not just about integrating existing solutions; we're about creating new possibilities. Our team specializes in advanced AI and automation technologies. While we do handle popular requests like AI-driven chatbots and seamless integrations with platforms like SMS, Facebook, and Instagram, we also excel in developing custom solutions. This includes complex workflows on CRM platforms and specialized automation tasks that go beyond the capabilities of tools like Zapier & Make. Our engineers have a decade of experience in crafting intricate, tailor-made software solutions. If you're not sure whether we can handle your unique needs, feel free to drop us a line at info@aipeakflow.com."
  },
  {
    question: "Any limit on how much work I can send your way?",
      answer: "We offer a flexible service tailored to your needs. While you can submit multiple tasks, we focus on 1 or 2 at a time to ensure each receives the high-quality attention it deserves. Our pricing is transparent, providing excellent value without hidden fees."
  },
  {
    question: "How quick can you get things done?",
    answer: "We aim to finish tasks as quickly as possible while maintaining high quality. If a project is complex, we'll keep you updated every step of the way."
  },
  {
    question: "Who's the brain behind the operation?",
    answer: "Our team comprises top engineers with a decade of experience in developing complex and advanced software solutions. You'll be directly collaborating with our in-house experts. On rare occasions, we may bring in specialized consultants for tasks that require niche expertise, and we'll absorb any additional costs to ensure your project's success."
  },
  {
    question: "What if I don't like what I get?",
    answer: "Your satisfaction is our top priority. If the delivered work doesn't meet your expectations, we'll continue to refine it until you're completely satisfied, guaranteed."
  }
];


  return (
    <div className={styles.faqSection}>
      <div className={styles.title}>FAQ</div>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`${styles.faqCard} ${openIndex === index ? styles.open : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className={styles.faqToggle}>
            <div className={styles.faqIcon}>+</div>
            <div>{faq.question}</div>
          </div>
          <div className={`${styles.faqAnswer} ${styles.faqAnswerStyle}`} dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
