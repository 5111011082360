export const services = [  {
    name: ["AI-Driven", " Business Solutions for Scalable Growth"],
    services: [
      "Optimize your operations with our AI solutions. We automate tasks, deliver actionable insights, and offer 24/7 support for scalable, sustainable growth across all business functions.",
    ],
  },
  {
    name: ["Customer Engagement &", " Support"],
    services: [
      "Elevate your customer experience with our intelligent chatbots and virtual assistants. Available 24/7, they handle queries, support tasks, and much more, freeing your team to focus on strategic initiatives.",
    ],
  },
  {
    name: ["Sales & ", "Marketing Optimization"],
    services: [
      "Maximize your revenue streams with our AI-powered sales and marketing tools. From identifying potential customers to optimizing conversion paths, we help you achieve unparalleled growth."
    ],
  },
];
