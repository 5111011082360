import { useEffect } from "react";const About = () => {
  const leftImage = "/images/avatar.png";

  // useEffect(() => {
  //   const image = document.getElementById("image");
  //   const paragraph = document.getElementById("paragraph");

  //   // Set initial height of image to match paragraph
  //   image.style.height = `${paragraph.offsetHeight}px`;

  // });
  // useEffect(() => {
  //   // Create script element
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
  //   script.onload = function () {
  //     window.voiceflow.chat.load({
  //       verify: { projectID: "6511609959102700086daf86" },
  //       url: "https://general-runtime.voiceflow.com",
  //       versionID: "production",
  //     });
  //   };

  //   // Append script element to document head
  //   document.head.appendChild(script);

  //   // Clean up on component unmount
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);

  return (
    <div className="text-mainf container flex flex-col items-center justify-center md:flex-row py-20 lg:py-32">
      <div id="paragraph" className="w-full">
        <div className="my-0">
          <p className="title">About Us</p>

          <p className="mx-auto max-w-[790px] text-center text-base text-[#4D5761] md:text-xl md:line-h">
            At AIPeakFlow, we don't just offer solutions; we engineer 
            milestones for your business journey. Specializing in AI 
            automation and development, we are the architects behind some 
            of the most innovative AI-driven ecosystems in the industry.

            Our mission is to transform the way businesses operate, 
            making them smarter, more efficient, and remarkably agile. 
            Our vision? A world where every business, regardless of size 
            or industry, has access to the transformative power of AI.

          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
