import { Link } from "react-router-dom";
const Header = () => {

  return (
    <div className="absolute top-0 left-0 w-full h-[100px] z-[2]">
      <div className="container flex items-center justify-between">
        <div className="flex flex-row items-center justify-center">
          <div className="">
            <a href="/">
              <img
                src="/images/Logo/mark.png"
                className="w-[60px] h-[60px]"
                alt="logo"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-row font-['Inter'] text-base">
          <a className = "btn btnWhiteText" href="#services" type="button">
            Our services
          </a>
          <Link className = "btn blue-btn"  onClick={openCalendly} to="/"  >
            Book a free consultation
          </Link>
          
        </div>
      </div>
    </div>
  );
};

 const openCalendly = (e) => {
    e.preventDefault();
    window.open('https://calendly.com/info-dm0/explore-the-future-aipeakflow', '_blank');
  };

export default Header;
